<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div style="background: #ecf0f5">
    <v-container fluid>
      <v-row style="margin-top: -20px" dense>
        <v-col>
          <v-breadcrumbs :items="items" light>
            <template v-slot:divider>
              <v-icon small>$vuetify.icons.values.mdiChevronRight</v-icon>
            </template>
          </v-breadcrumbs>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span><v-icon large class="mr-3">$vuetify.icons.values.mdiChartPie</v-icon></span>
          <span>{{$t('msg.group_results_page_heading_name')}}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-app-bar dark color="#8C92DC">
              <v-icon>$vuetify.icons.values.mdiMagnify</v-icon>
              <v-toolbar-title>{{$t('msg.filters')}}</v-toolbar-title>
            </v-app-bar>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" md="3" lg="3">
                  <v-select v-model="filterBox.assessment" :items="assessmentDropDown"
                            :label="$t('msg.assessmentDropdownLabel')"  clearable outlined>
                  </v-select>
                </v-col>
                <v-col cols="12" md="9" lg="9" justify-self="end"
                       class="d-flex justify-lg-end justify-md-end justify-center mt-3">
                  <v-btn color="#5660A5" dark class="mr-3" @click="filter">{{$t('msg.search')}}</v-btn>
                  <v-btn color="#5660A5" dark @click="reset">{{$t('msg.reset')}}</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-overlay :value="overlay">
      <v-dialog
        v-model="overlay"
        hide-overlay
        persistent
        width="300"
      >
        <v-card
          color="black"
          dark
        >
          <v-card-text>
            {{$t('msg.loading')}}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-overlay>
    <v-container fluid style="padding-bottom: 70px">
      <v-row>
        <v-col>
          <v-card>
            <v-app-bar
              dark
              color="#8C92DC">
              <v-toolbar-title>{{$t('msg.table_heading_group')}}</v-toolbar-title>
            </v-app-bar>
            <v-card-title>
              <v-row>
                <v-col md="8" lg="8" class="d-none d-md-flex d-lg-flex"></v-col>
                <v-text-field hide-details :placeholder="$t('msg.search_placeholder')" single-line v-model="search"
                              v-on:keyup.enter="filter">
                </v-text-field>
                <v-btn class="mt-2" color="#5660A5" dark @click="filter">
                  <v-icon>$vuetify.icons.values.mdiMagnify</v-icon>
                </v-btn>
              </v-row>
            </v-card-title>
            <v-row dense>
              <v-col>
                <v-data-table :headers="headers" :items="table.tableData" :options.sync="pagination"
                              :disable-sort="true" :items-per-page=pagination.itemPerPage @update:options="paginate"
                              :server-items-length=table.totalLength
                              :footer-props="{
                            'items-per-page-options': pagination.itemPerPageOptions,
                            'show-current-page':true
                            }">
                  <template v-slot:item.file_name="{ item }">
                    <span v-if="item.file_name">
                      <span v-if="checkArray(item.id)">
                        <v-icon style="cursor: pointer" color="green" v-bind:key="item.id"
                                @click.native="downloadReport(item.id,item.name,item.duration,item.file_name)">
                          $vuetify.icons.values.mdiFileDownloadOutline
                        </v-icon>
                      </span>
                      <span v-else>
                        <v-progress-circular indeterminate :size="20" color="#8C92DC"></v-progress-circular>
                      </span>
                    </span>
                    <span v-else>
                      <v-icon disabled>$vuetify.icons.values.mdiFileDownloadOutline</v-icon>
                    </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="reportDownloadError" :bottom="true" dark color="#8C92DC" :right="true" :timeout="6000"
                :vertical="true">
      <span v-html="$t('msg.report_unavailable')"></span>
      <v-btn dark text @click="reportDownloadError = false" v-html="$t('msg.close_label')"></v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import { dashboardService, downloadFileService } from '../_services'
import fileSaver from 'file-saver'

export default {
  name: 'Results',
  data () {
    return {
      overlay: false,
      exporting: false,
      exportDataDownloadError: false,
      assessmentDropDown: [],
      requestDataArray: [''],
      date: new Date().toJSON(),
      loadingDataTable: false,
      reportDownloadError: false,
      menu: false,
      menu2: false,
      dialog: false,
      filterBox: {
        assessment: ''
      },
      search: '',
      pagination: {
        itemsPerPage: 10,
        itemPerPageOptions: [10],
        assessment: '',
        searchedText: ''
      },
      table: {
        tableData: [],
        totalLength: 0
      },
      items: [
        {
          text: 'Home',
          disabled: true,
          href: 'breadcrumbs_dashboar'
        },
        {
          text: 'Group Results',
          disabled: true,
          href: 'breadcrumbs_link_1'
        }
      ],
      headers: [
        { text: this.$t('msg.group_table_header_no'), align: 'center', sortable: false, value: 'slNo' },
        { text: this.$t('msg.group_table_header_name'), align: 'left', value: 'name' },
        { text: this.$t('msg.group_table_header_assessment'), align: 'center', value: 'test_name' },
        { text: this.$t('msg.group_table_header_assessment_duration'), align: 'center', value: 'duration' },
        { text: this.$t('msg.group_table_header_report'), align: 'center', value: 'file_name' }
      ]
    }
  },
  methods: {
    populateDataForSelects: function () {
      this.overlay = true
      dashboardService.fetchDataForGroupReportPageDropDowns()
        .then(response => {
          if (response.dataResponse) {
            if (response.dataResponse.Assessments) {
              this.assessmentDropDown = response.dataResponse.Assessments
            }
          }
          this.overlay = false
        }).catch(error => {
          this.overlay = false
          /* eslint-disable no-console */
          console.log('loggin response ' + error)
        /* eslint-enable no-console */
        })
    },
    getDataFromApi: function () {
      this.overlay = true
      let data = {
        pageSize: this.pagination.itemsPerPage,
        pageNumber: this.pagination.page,
        searchText: this.search,
        assessment: this.pagination.assessment
      }
      dashboardService.fetchGroupReportListData(data)
        .then(response => {
          if (response.dataResponse && response.dataResponse.data) {
            this.table.tableData = response.dataResponse.data.table_content
            this.table.totalLength = response.dataResponse.data.total_rows
          }
          this.overlay = false
        }).catch(error => {
          this.overlay = false
          /* eslint-disable no-console */
          console.log('loggin response ' + error)
        /* eslint-enable no-console */
        })
    },
    paginate: function () {
      this.getDataFromApi()
    },
    filter: function () {
      this.pagination.assessment = this.filterBox.assessment
      this.pagination.searchedText = this.search
      this.pagination.page = 1
      this.paginate()
    },
    reset: function () {
      this.filterBox.assessment = ''
      this.pagination.assessment = ''
      this.pagination.page = 1
      this.paginate()
    },
    downloadReport: function (groupReportId, name, duration, fileName) {
      this.reportDownloadError = false
      if (this.requestDataArray.indexOf(groupReportId) < 0) {
        this.requestDataArray.push(groupReportId)
      }
      downloadFileService.downloadGroupReport({ id: groupReportId })
        .then(response => {
          if (response) {
            fileSaver.saveAs(response, name + '-' + duration + '.' + fileName.split('.').pop())
          }
          this.requestDataArray.splice(this.requestDataArray.indexOf(groupReportId), 1)
          this.loadingDataTable = false
        }).catch(error => {
          this.loadingDataTable = false
          this.requestDataArray.splice(this.requestDataArray.indexOf(groupReportId), 1)
          this.reportDownloadError = true
          /* eslint-disable no-console */
          console.log('loggin response ' + error)
          /* eslint-enable no-console */
        })
    },
    checkArray: function (groupReportId) {
      return this.requestDataArray.indexOf(groupReportId) < 0
    }
  },
  mounted () {
    this.$store.dispatch('home/setCurrentPage', 'groupReports')
    this.$cookies.set('standard_current_page', 'groupReports')
    this.populateDataForSelects()
  }
}
</script>

<style scoped>
  .v-breadcrumbs {
    padding: 7px 0px 0px 0px !important;
  }
</style>
<style>
  .v-input input{
    cursor: pointer !important;
  }
  .v-input__slot{
    cursor: default !important;
  }
  .removeFilterBottomMargin{
    margin-bottom: -20px;
  }
  .dashboardFooterBottomMargin{
    margin-bottom: 100px;
  }
</style>
